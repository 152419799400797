import '../bootstrap'
import NotificationToast from "../libs/NotificationToast";
import {customAlert, CustomConfirm, CustomConfirmLogout, CustomConfirmValidation} from "../libs/CustomConfirm";
import autoComplete from "@tarekraafat/autocomplete.js";
import formControl from "../libs/FormControl";

import {SelectInput, SelectInputAddable} from "../Components/SelectInput";
import {DatePicker, DatePickerTwo, TimePicker} from "../Components/DatePicker";
import {InputNumeric} from "../Components/InputNumeric";
import {TextEditor} from "../Components/TextEditor";
import {calendar} from "../Components/calendar";
import Chart from 'chart.js/auto';
import autocolors from 'chartjs-plugin-autocolors';
import axios from "axios";
import AutoNumeric from "autonumeric";
import readXlsxFile from "read-excel-file";
import SlimSelect from "../libs/SlimSelect/slimselect.min";
import $ from "jquery";
import {round} from "lodash";

let Turbolinks = require("turbolinks")
Turbolinks.start()

//Custom Select Input
customElements.define("custom-select", SelectInput, {extends: "select"})
customElements.define("custom-select-addable", SelectInputAddable, {extends: "select"})
customElements.define("time-picker", TimePicker, {extends: "input"})
customElements.define("date-picker", DatePicker, {extends: "input"})
customElements.define("date-picker-two", DatePickerTwo, {extends: "input"})
customElements.define("number-format", InputNumeric, {extends: "input"})
customElements.define("text-editor", TextEditor, {extends: "textarea"})
customElements.define("calendar-form", calendar, {extends: "div"})

function getAllFormSubit() {
    let allForm = document.querySelectorAll(".modal__body form")
    allForm.forEach((item) => {
        item.addEventListener("submit", (e) => {
            e.preventDefault()
            document.querySelector(".modal__box.active .modal__footer .form__submit__btn").click()
        })
    })
}

document.addEventListener("turbolinks:load", function () {

    /**
     * Notifucation Toast
     */
    try {
        let notifyMSG = notification ?? null
        if (notifyMSG !== null) {
            NotificationToast(notifyMSG.type, notifyMSG.message)
        }
    } catch (e) {

    }

// Autoclose dropdown
    window.addEventListener("click", (e) => {
        if (!e.target.matches('.icon.fi-rr-menu-dots-vertical') && !e.target.matches('.table_drop_action')) {
            document.querySelectorAll('.table_drop_action').forEach(elm => {
                if (elm.classList.contains("active")) {
                    elm.classList.remove("active");
                }
            });
        }

        if (document.querySelector(".dropdown .default_option") !== null) {
            if (!e.target.matches('.dropdown .default_option')) {
                if (document.querySelector(".dropdown .default_option + ul").classList.contains("active")) {
                    document.querySelector(".dropdown .default_option + ul").classList.remove("active");
                }
            }
        }

        if (!e.target.matches('.default_option')) {
            document.querySelectorAll('.dropdownList:not(.noEvent) > ul').forEach(elm => {
                if (elm.classList.contains("active")) {
                    elm.classList.remove("active");
                }
            });
        }
    });

// Gestion de la suppression
    let formDelete = document.querySelectorAll(".deleteForm");
    formDelete.forEach((item) => {
        item.addEventListener("submit", (e) => {
            e.preventDefault();
            let text = item.dataset.content
            let btn = item.dataset.btn
            CustomConfirm(item, text, btn)
        })
    })

// Table DropDown Action menu
    let table_drop_action = document.querySelectorAll('.table_drop_action');
    table_drop_action.forEach(element => {
        element.addEventListener("click", () => {
            table_drop_action.forEach(elm => {
                if (elm.classList.contains("active") && elm !== element) {
                    elm.classList.remove("active");
                }
            });
            element.classList.toggle("active");
        });
    });

// Navigation sidebar toggle

    let sidebar = document.querySelector(".sidebar");
    let sidebarBtn = document.querySelector("#close-nav");
    sidebarBtn?.addEventListener("click", () => {
        sidebar.classList.toggle("close")
        menuBtnChange()
        localStorage.setItem("sidebarClose", sidebar.classList.contains("close").toString())
    });

    if (sidebar !== null) {
        loadTheme()
    }

    function loadTheme() {
        let sidebarClose = localStorage.getItem("sidebarClose")

        if (sidebarClose !== null && sidebarClose !== undefined && sidebarClose === 'true') {
            sidebar.classList.add('notransition')
            sidebar.querySelectorAll("li").forEach((item) => {
                item.classList.add('notransition')
            })
            sidebar.querySelectorAll("li i").forEach((item) => {
                item.classList.add('notransition')
            })
            sidebar.querySelectorAll("li .sub-menu").forEach((item) => {
                item.classList.add('notransition')
            })
            sidebar.querySelectorAll("li a .link_name").forEach((item) => {
                item.classList.add('notransition')
            })
            document.querySelector(".home-section").classList.add('notransition')
            document.querySelector(".profile-details").classList.add('notransition')
            document.querySelector(".sidebar .logo-details .icon").classList.add('notransition')
            document.querySelector(".sidebar .logo-details #close-nav").classList.add('notransition')
            sidebar.classList.add("close");
        }
    }

// Navigation sidebar control
    let nav_dropdown_link = document.querySelectorAll(".dropdown-link");
    for (let i = 0; i < nav_dropdown_link.length; i++) {
        nav_dropdown_link[i].addEventListener("click", (e) => {
            if (sidebar.classList.contains("close") === false) {
                let nav_dropdown_linkParent = nav_dropdown_link[i].parentElement.parentElement;
                nav_dropdown_linkParent.classList.toggle("showMenu")
            }
        });
    }

    // Filter Drop
    let filterDrop = document.querySelector(".dropdown .default_option");
    if (filterDrop !== null && filterDrop !== undefined) {
        filterDrop.addEventListener("click", () => {
            let filterElm = document.querySelector(".dropdown .default_option + ul");
            filterElm.classList.toggle("active");
        });
    }

    let dropdownList = document.querySelectorAll(".dropdownList .default_option");
    dropdownList.forEach((dropdown) => {
        let dropdownListElm = dropdown.parentElement.querySelector("ul");
        if (dropdownList !== null && dropdownList !== undefined) {
            dropdown.addEventListener("click", () => {
                dropdownListElm.classList.toggle("active");
            });
        }
    })


    function menuBtnChange() {
        if (!sidebar.classList.contains("close")) {
            sidebarBtn.classList.replace("fi-rr-menu-burger", "fi-rr-align-right")
        } else {
            sidebarBtn.classList.replace("fi-rr-align-right", "fi-rr-menu-burger")
        }
    }

// Gestion de bar de recherche
    let searchField = document.querySelector(".search_field .input")
    if (searchField !== null && searchField !== undefined) {
        const autoCompleteJS = new autoComplete({
            selector: "#autoComplete", placeHolder: "Rechercher...", data: {
                src: searchList, cache: true,
            }, resultsList: {
                element: (list, data) => {
                    if (!data.results.length) {
                        // Create "No Results" message element
                        const message = document.createElement("div");
                        // Add class to the created element
                        message.setAttribute("class", "no_result");
                        // Add message text content
                        message.innerHTML = `<span>Aucun résultat trouvé pour "${data.query}"</span>`;
                        // Append message element to the results list
                        list.prepend(message);
                    }
                }, noResults: true, maxResults: 15, tabSelect: true,
            }, // submit form
            submit: true, resultItem: {
                highlight: true
            }, events: {
                input: {
                    selection: (event) => {
                        const selection = event.detail.selection.value;
                        let url = window.location.origin + window.location.pathname
                        const params = new URLSearchParams({
                            q: selection
                        });
                        Turbolinks.visit(url + '?' + params.toString());
                    }
                }
            }
        });
    }


    let JS_Call_Url_Get_Form = document.querySelectorAll(".JS_Call_Url_Get_Form")
    JS_Call_Url_Get_Form.forEach((item) => {
        item.addEventListener('click', () => {
            let url = item.dataset.url
            if (url !== null && url !== undefined) {
                axios({
                    method: 'get', url: url, responseType: 'text',
                })
                    .then(function (response) {
                        let generateEditForm = document.querySelector("#JS_GenerateForm")
                        let data = response.data
                        if (data === undefined || generateEditForm === null) {
                            return 0
                        }
                        generateEditForm.innerHTML = data

                        // Toggle Switch Edit Modal
                        toggleEditForm()

                        // requiredText
                        let requiredText = generateEditForm.querySelector("[data-control ~= 'noEmpty']")
                        if (requiredText !== null) {
                            if (generateEditForm.querySelector(".required__placeholder") == null) {

                                let paragraph = document.createElement('p')
                                paragraph.classList.add('required__placeholder')
                                paragraph.innerHTML = `<i class="fi-rr-exclamation"></i> champs obligatoires`

                                generateEditForm.querySelector(".modal__container").insertBefore(paragraph, generateEditForm.querySelector(".modal__footer"))
                            }
                        }

                        setTimeout(function () {
                            generateEditForm.querySelector('.modal__box').classList.add('active')
                        }, 100)


                        // Show/Hidden Password
                        let password_field = document.querySelectorAll("#passswordToggle")
                        password_field.forEach((item) => {
                            item.addEventListener("click", () => {
                                let statut = item.dataset.statut
                                if (statut === "hidden") {
                                    item.parentElement.querySelector("input").type = "text"
                                    item.dataset.statut = "visible"
                                    item.classList.remove('fi-rr-eye-crossed')
                                    item.classList.add('fi-rr-eye')
                                } else {
                                    item.parentElement.querySelector("input").type = "password"
                                    item.dataset.statut = "hidden"
                                    item.classList.add('fi-rr-eye-crossed')
                                    item.classList.remove('fi-rr-eye')
                                }
                            })
                        })

                        // Close Modal
                        let close__modal = generateEditForm.querySelectorAll(".close__modal")
                        if (close__modal !== null && close__modal !== undefined) {
                            close__modal.forEach((item) => {
                                item.addEventListener("click", () => {
                                    CloseModal()
                                })
                            })
                        }

                        // Submit Form
                        let modalForm__submit = generateEditForm.querySelector(".modalForm__submit")
                        if (modalForm__submit !== null && modalForm__submit !== undefined) {
                            modalForm__submit.addEventListener("click", () => {
                                submitForm(modalForm__submit)
                            })

                            let form = generateEditForm.querySelector("form")
                            form.addEventListener("submit", (e) => {
                                e.preventDefault()
                                submitForm(modalForm__submit)
                            })
                        }

                        let depense_form = generateEditForm.querySelector("form#depense_form")
                        if (depense_form !== null) {
                            let type_paiement = depense_form.querySelector("select#type_paiement")
                            let montant = depense_form.querySelector("input#montant")
                            type_paiement.addEventListener("change", function () {
                                let max = type_paiement.options[type_paiement.selectedIndex].dataset.max
                                if (max) {
                                    if (parseFloat(montant.value.replaceAll(' ', '').replaceAll(',', '.')) > parseFloat(max)) {
                                        AutoNumeric.set(montant, 0, {maximumValue: max})
                                    } else {
                                        AutoNumeric.set(montant, montant.value, {maximumValue: max})
                                    }
                                }
                            })
                        }

                        let banque_transfert = generateEditForm.querySelector("form#banque_transfert")
                        if (banque_transfert !== null) {
                            let banque_id = banque_transfert.querySelector("select#banque_id")
                            let montant = banque_transfert.querySelector("input#montant")
                            banque_id.addEventListener("change", function () {
                                let max = banque_id.options[banque_id.selectedIndex].dataset.max
                                if (max) {
                                    if (parseFloat(montant.value.replaceAll(' ', '').replaceAll(',', '.')) > parseFloat(max)) {
                                        AutoNumeric.set(montant, 0, {maximumValue: max})
                                    } else {
                                        AutoNumeric.set(montant, montant.value, {maximumValue: max})
                                    }
                                }
                            })
                        }

                        let modePU = generateEditForm.querySelector("#article_form #mode")
                        if (modePU !== null) {
                            let pht = generateEditForm.querySelector("#article_form #pu")
                            let pttc = generateEditForm.querySelector("#article_form #puttc")
                            modeDisable(modePU.value.replaceAll(' ', '').replaceAll(',', '.'))
                            modePU.addEventListener("change", () => {
                                let value = modePU.value
                                modeDisable(value)
                            });

                            function modeDisable(value) {
                                if (parseInt(value, 10) === 0) {
                                    pttc.disabled = false;
                                    pttc.removeAttribute("forcedisabled")

                                    pht.value = ""
                                    pht.disabled = true;
                                    pht.setAttribute("forcedisabled", 'true')
                                } else {
                                    pht.disabled = false;
                                    pht.removeAttribute("forcedisabled")

                                    pttc.value = ""
                                    pttc.disabled = true;
                                    pttc.setAttribute("forcedisabled", 'true')
                                }
                            }
                        }

                        let client_upload = generateEditForm.querySelector("#client_upload #upload_image_file")
                        if (client_upload !== null) {
                            client_upload.addEventListener('change', () => {
                                readXlsxFile(client_upload.files[0]).then((rows) => {
                                    let head = rows[0] || []

                                    let allSelect = generateEditForm.querySelectorAll('table select')
                                    allSelect.forEach((item) => {

                                        item.removeAttribute("is")

                                        item.querySelectorAll("option:not([data-placeholder])").forEach((elm) => {
                                            elm.remove()
                                        })

                                        let select = new SlimSelect({
                                            select: item
                                        })
                                        select.destroy()
                                        item.nextElementSibling.remove()

                                        head.forEach((elm_head, index) => {
                                            let opt = document.createElement("option");
                                            opt.value = index;
                                            opt.text = elm_head;
                                            item.add(opt)
                                        })
                                        // setCustomSelect(item)

                                    })
                                })
                            })
                        }
                        let type_client = generateEditForm.querySelector("#addClient #type")
                        if (type_client !== null) {
                            type_client.addEventListener("change", () => {
                                let span = generateEditForm.querySelector("#include__form")
                                let url = generateEditForm.querySelector("form").getAttribute("data-c" + type_client.value.replaceAll(' ', '').replaceAll(',', '.'))
                                let animation = generateEditForm.querySelector(".modal__container")
                                animation.classList.toggle("show__loader")
                                axios({
                                    method: 'get', url: url, responseType: 'text',
                                })
                                    .then(function (response) {
                                        span.innerHTML = response.data
                                        setTimeout(function () {
                                            animation.classList.toggle("show__loader")
                                        }, 500)
                                    }).catch(function (error) {
                                    NotificationToast("error", error.message)
                                });
                            })
                        }
                        let exoneration_client = generateEditForm.querySelector("#addClient #exoneration_fiscale")
                        if (exoneration_client !== null) {
                            exoneration_client.addEventListener("change", () => {
                                let value = exoneration_client.value
                                if (parseInt(value, 10) === 1) {
                                    NotificationToast("warning", "Veuillez renseigner la référence d'exoneration")
                                    generateEditForm.querySelector("#addClient #reference_exoneration").setAttribute("data-control", "noEmpty")
                                } else {
                                    generateEditForm.querySelector("#addClient #reference_exoneration").removeAttribute("data-control")
                                }
                            })
                        }

                        let addTableRow = document.querySelector(".addTableRow")
                        if (addTableRow != null) {
                            addTableRow.addEventListener('click', () => {
                                let form = generateEditForm.querySelector("form")
                                let data = new FormData(form)
                                let control = formControl(form, data)
                                if (control === true) {
                                    let table = document.querySelector(".dynamicRow")
                                    let rowTemplate = document.querySelector("#row_template").cloneNode(true)

                                    // Change produit
                                    let id = rowTemplate.querySelector(".td_bordered input[name='article_id[]']")
                                    let data_tva = rowTemplate.querySelector(".td_bordered input[name='data_tva[]']")

                                    let idService = null
                                    if (rowTemplate.querySelector(".td_bordered input[name='service_id[]']") !== null) {
                                        idService = rowTemplate.querySelector(".td_bordered input[name='service_id[]']")
                                    }

                                    let ts = ""
                                    if (rowTemplate.querySelector(".td_bordered input[name='ts[]']") !== null) {
                                        ts = rowTemplate.querySelector(".td_bordered input[name='ts[]']")
                                    }

                                    let produit = rowTemplate.querySelector(".produit")
                                    let pu = rowTemplate.querySelector(".td_bordered input[name='pu[]']")
                                    let quantite = rowTemplate.querySelector(".td_bordered input[name='quantite[]']")
                                    let pHT = rowTemplate.querySelector(".td_bordered input[name='pHT[]']")
                                    let tva = rowTemplate.querySelector(".td_bordered input[name='tva[]']")
                                    let pTTC = rowTemplate.querySelector(".td_bordered input[name='pTTC[]']")
                                    let iPS = null

                                    iPS = data.get('produit')

                                    if (form.querySelector("#produit option[value='" + iPS + "']").getAttribute("data-service") == 'true') {
                                        idService.value = iPS
                                    } else {
                                        id.value = iPS
                                    }
                                    let checkExistArticle = table.querySelector("input[name='article_id[]'][value='" + data.get('produit') + "']")
                                    if (checkExistArticle === null) {
                                        checkExistArticle = table.querySelector("input[name='service_id[]'][value='" + data.get('produit') + "']")
                                    }

                                    if (checkExistArticle == null) {

                                        produit.innerHTML = document.querySelector('.modal__box #produit').options[document.querySelector('.modal__box #produit').selectedIndex].text

                                        if (form.querySelector("#produit option[value='" + iPS + "']").dataset.prix !== undefined) {
                                            pu.value = form.querySelector("#produit option[value='" + iPS + "']").dataset.prix
                                            data.set('pu[]', form.querySelector("#produit option[value='" + iPS + "']").dataset.prix)
                                        } else {
                                            pu.value = data.get('pu[]')
                                        }

                                        let tsValue = 0;
                                        // Taxe specifique
                                        if (form.querySelector("#produit option[value='" + iPS + "']").dataset.ts !== undefined) {
                                            tsValue = parseFloat(form.querySelector("#produit option[value='" + iPS + "']").dataset.ts)
                                            if (isNaN(tsValue)) {
                                                tsValue = 0
                                            }
                                            let ts_typeValue = Boolean(form.querySelector("#produit option[value='" + iPS + "']").dataset.tstype)
                                            if (ts_typeValue === false) {
                                                rowTemplate.querySelector(".td_bordered input[name='data_ts[]']").value = tsValue
                                                rowTemplate.querySelector(".td_bordered input[name='data_tstype[]']").value = "true"
                                                tsValue = (parseFloat(data.get('pu[]')) * parseFloat(data.get('quantite[]'))) * tsValue / 100
                                            } else {
                                                tsValue = tsValue
                                                rowTemplate.querySelector(".td_bordered input[name='data_ts[]']").value = tsValue
                                                rowTemplate.querySelector(".td_bordered input[name='data_tstype[]']").value = "false"
                                            }
                                            ts.value = number_format(tsValue, 2, '.', ' ')
                                        }


                                        quantite.value = data.get('quantite[]')
                                        pHT.value = number_format(parseFloat(data.get('pu[]')) * parseFloat(data.get('quantite[]')), 2, ".", " ")
                                        let taux = form.querySelector("#produit option[value='" + iPS + "']")
                                        taux = taux !== null ? taux.dataset.tva : 0

                                        tva.value = taux;
                                        // TVA
                                        data_tva.value = taux;

                                        if (table.dataset.disableTva === "true") {
                                            taux = 0;
                                        }

                                        let ht = parseFloat(pHT.value.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.'))
                                        pTTC.value = number_format((ht + tsValue) + ((ht + tsValue) * (taux / 100)), 2, ".", " ")
                                        table.querySelector("tbody").append(rowTemplate.querySelector(".td_bordered"))
                                    } else {
                                        let qte = checkExistArticle.parentElement.parentElement.querySelector("input[name='quantite[]']")
                                        qte.value = parseFloat(qte.value.replaceAll(' ', '').replaceAll(',', '.')) + parseFloat(data.get('quantite[]'))

                                        let puA = checkExistArticle.parentElement.parentElement.querySelector("input[name='pu[]']")
                                        let pHTA = checkExistArticle.parentElement.parentElement.querySelector("input[name='pHT[]']")
                                        let pTTCA = checkExistArticle.parentElement.parentElement.querySelector("input[name='pTTC[]']")
                                        let pTVA = checkExistArticle.parentElement.parentElement.querySelector("input[name='tva[]']")
                                        let pTS = checkExistArticle.parentElement.parentElement.querySelector("input[name='ts[]']")
                                        let remise = checkExistArticle.parentElement.parentElement.querySelector("input[name='remise[]']")

                                        let ht = parseFloat(puA.value.replaceAll(' ', '').replaceAll(',', '.')) * parseFloat(qte.value.replaceAll(' ', '').replaceAll(',', '.'))
                                        let tsValue = 0

                                        let remise_value = 0
                                        if (remise !== null) {
                                            if (remise.value.includes('%')) {
                                                let value = parseFloat(remise.value.split("%")[0].replaceAll(' ', '').replaceAll(',', '.'))
                                                value = parseFloat(value)
                                                remise_value = ht * (value / 100)
                                            } else {
                                                remise_value = parseFloat(remise.value.replaceAll(' ', '').replaceAll(',', '.'))
                                            }
                                        }

                                        if (pTS !== null) {
                                            tsValue = parseFloat(form.querySelector("#produit option[value='" + iPS + "']").dataset.ts)
                                            if (isNaN(tsValue)) {
                                                tsValue = 0
                                            }
                                            let ts_typeValue = Boolean(form.querySelector("#produit option[value='" + iPS + "']").dataset.tstype)
                                            if (ts_typeValue === false) {
                                                tsValue = (ht - remise_value) * (tsValue / 100)
                                            } else {
                                                tsValue = tsValue * qte.value
                                            }
                                            pTS.value = number_format(tsValue, 2, ".", " ")
                                        }

                                        pHTA.value = number_format(ht, 2, ".", " ")
                                        pTTCA.value = number_format((((ht - remise_value)) + tsValue) + (((ht - remise_value) + tsValue) * (parseFloat(pTVA.value.replaceAll(' ', '').replaceAll(',', '.')) / 100)), 2, ".", " ")

                                        calculTotal()
                                    }
                                    CloseModal()
                                }
                            })
                        }

                        // Checkbox Select
                        let check_item_create = generateEditForm.querySelector("input#check_item_all_create")
                        let check_item_all_create = generateEditForm.querySelectorAll('.modal__body input[value $= "create"]')

                        // --------- READ ----- //
                        let check_item_read = generateEditForm.querySelector("input#check_item_all_read")
                        let check_item_all_read = generateEditForm.querySelectorAll('.modal__body input[value $= "read"]')

                        // --------- UPDATE ----- //
                        let check_item_update = generateEditForm.querySelector("input#check_item_all_update")
                        let check_item_all_update = generateEditForm.querySelectorAll('.modal__body input[value $= "update"]')

                        // --------- DELETE ----- //
                        let check_item_delete = generateEditForm.querySelector("input#check_item_all_delete")
                        let check_item_all_delete = generateEditForm.querySelectorAll('.modal__body input[value $= "delete"]')

                        check_item_create?.addEventListener('click', () => {
                            let value = check_item_create.checked
                            if (value) {
                                check_item_all_create.forEach((item) => item.checked = true)
                            } else {
                                check_item_all_create.forEach((item) => item.checked = false)
                            }
                        })

                        check_item_read?.addEventListener('click', () => {
                            let value = check_item_read.checked
                            if (value) {
                                check_item_all_read.forEach((item) => item.checked = true)
                            } else {
                                check_item_all_read.forEach((item) => item.checked = false)
                            }
                        })

                        check_item_update?.addEventListener('click', () => {
                            let value = check_item_update.checked
                            if (value) {
                                check_item_all_update.forEach((item) => item.checked = true)
                            } else {
                                check_item_all_update.forEach((item) => item.checked = false)
                            }
                        })

                        check_item_delete?.addEventListener('click', () => {
                            let value = check_item_delete.checked
                            if (value) {
                                check_item_all_delete.forEach((item) => item.checked = true)
                            } else {
                                check_item_all_delete.forEach((item) => item.checked = false)
                            }
                        })

                    }).catch(function (error) {
                    NotificationToast("error", error.message)
                });
            }
        })
    })

    // Point de vente articles
    function getCardArticle() {
        let pointVenteArticle = document.querySelectorAll(".pointVenteArticle")
        if (pointVenteArticle != null) {
            pointVenteArticle.forEach((item) => {
                item.addEventListener('click', () => {
                    let data = item.dataset
                    let control = true
                    if (control === true) {
                        let table = document.querySelector(".dynamicRow")
                        let rowTemplate = document.querySelector("#row_template").cloneNode(true)

                        // Change produit
                        let id = rowTemplate.querySelector(".td_bordered input[name='article_id[]']")
                        let data_tva = rowTemplate.querySelector(".td_bordered input[name='data_tva[]']")


                        let idService = null
                        if (rowTemplate.querySelector(".td_bordered input[name='service_id[]']") !== null) {
                            idService = rowTemplate.querySelector(".td_bordered input[name='service_id[]']")
                        }

                        let ts = ""
                        if (rowTemplate.querySelector(".td_bordered input[name='ts[]']") !== null) {
                            ts = rowTemplate.querySelector(".td_bordered input[name='ts[]']")
                        }

                        let produit = rowTemplate.querySelector(".produit")
                        let pu = rowTemplate.querySelector(".td_bordered input[name='pu[]']")
                        let quantite = rowTemplate.querySelector(".td_bordered input[name='quantite[]']")
                        let pHT = rowTemplate.querySelector(".td_bordered input[name='pHT[]']")
                        let tva = rowTemplate.querySelector(".td_bordered input[name='tva[]']")
                        let pTTC = rowTemplate.querySelector(".td_bordered input[name='pTTC[]']")
                        let iPS = null

                        iPS = data.id

                        if (data.is_service == 'true') {
                            idService.value = iPS
                        } else {
                            id.value = iPS
                        }

                        let qte_stock = data.stock;
                        if (qte_stock !== undefined && qte_stock != null) {
                            rowTemplate.querySelector(".td_bordered").setAttribute("data-stk", parseFloat(qte_stock))
                        }
                        rowTemplate.querySelector(".td_bordered").setAttribute("data-id", iPS)

                        let checkExistArticle = table.querySelector("input[name='article_id[]'][value='" + iPS + "']")
                        if (checkExistArticle === null) {
                            checkExistArticle = table.querySelector("input[name='service_id[]'][value='" + iPS + "']")
                        }

                        if (checkExistArticle == null) {
                            produit.innerHTML = data.title
                            pu.value = data.pu

                            let tsValue = 0;
                            // Taxe specifique
                            if (data.ts !== undefined) {
                                tsValue = parseFloat(data.ts)
                                if (isNaN(tsValue)) {
                                    tsValue = 0
                                }
                                let ts_typeValue = Boolean(data.tstype)
                                if (ts_typeValue === false) {
                                    rowTemplate.querySelector(".td_bordered input[name='data_ts[]']").value = tsValue
                                    rowTemplate.querySelector(".td_bordered input[name='data_tstype[]']").value = "true"
                                    tsValue = (parseFloat(data.pu) * parseFloat('1')) * tsValue / 100
                                } else {
                                    tsValue = tsValue
                                    rowTemplate.querySelector(".td_bordered input[name='data_ts[]']").value = tsValue
                                    rowTemplate.querySelector(".td_bordered input[name='data_tstype[]']").value = "false"
                                }
                                ts.value = number_format(tsValue, 2, '.', ' ')
                            }

                            let qte = data.stock;
                            if (qte !== undefined && qte != null) {
                                if (parseFloat(qte) >= 1) {
                                    quantite.value = 1
                                } else {
                                    quantite.value = parseFloat(qte)
                                }
                            }

                            let taux = data.tva
                            taux = taux !== null ? data.tva : 0

                            tva.value = taux;
                            data_tva.value = taux;

                            if (table.dataset.disableTva === "true") {
                                taux = 0;
                            }

                            pHT.value = number_format((round((parseFloat(data.pu) * (1 + (taux / 100)))) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + (taux / 100)), 2, ".", " ")

                            let ht = parseFloat(pHT.value.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.'))
                            pTTC.value = number_format((ht + tsValue) + ((ht + tsValue) * (taux / 100)), 2, ".", " ")
                            table.querySelector("tbody").append(rowTemplate.querySelector(".td_bordered"))
                        } else {
                            let qte = checkExistArticle.parentElement.parentElement.querySelector("input[name='quantite[]']")
                            qte.value = parseFloat(qte.value.replaceAll(' ', '').replaceAll(',', '.')) + parseFloat('1')

                            let qte_stock = data.stock;
                            if (qte_stock !== undefined && qte_stock != null) {
                                if (parseFloat(qte.value.replaceAll(' ', '').replaceAll(',', '.')) > parseFloat(qte_stock)) {
                                    qte.value = parseFloat(qte_stock);
                                }
                            }

                            let puA = checkExistArticle.parentElement.parentElement.querySelector("input[name='pu[]']")
                            let pHTA = checkExistArticle.parentElement.parentElement.querySelector("input[name='pHT[]']")
                            let pTTCA = checkExistArticle.parentElement.parentElement.querySelector("input[name='pTTC[]']")
                            let pTVA = checkExistArticle.parentElement.parentElement.querySelector("input[name='tva[]']")
                            let pTS = checkExistArticle.parentElement.parentElement.querySelector("input[name='ts[]']")
                            let remise = checkExistArticle.parentElement.parentElement.querySelector("input[name='remise[]']")
                            let taux = parseFloat(pTVA.value.replaceAll(' ', '').replaceAll(',', '.'))

                            let ht = ((round(parseFloat(puA.value.replaceAll(' ', '').replaceAll(',', '.')) * (1 + (taux / 100)), 10) * parseFloat(qte.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + (taux / 100)))
                            let ht_rounded = round((round(parseFloat(puA.value.replaceAll(' ', '').replaceAll(',', '.')) * (1 + (taux / 100)), 10) * parseFloat(qte.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + (taux / 100)))
                            let tsValue = 0

                            let remise_value = 0
                            if (remise !== null) {
                                if (remise.value.includes('%')) {
                                    let value = parseFloat(remise.value.split("%")[0].replaceAll(' ', '').replaceAll(',', '.'))
                                    value = parseFloat(value)
                                    remise_value = parseFloat(puA.value.replaceAll(' ', '').replaceAll(',', '.')) * (value / 100)
                                } else {
                                    remise_value = parseFloat(remise.value.replaceAll(' ', '').replaceAll(',', '.'))
                                }
                            }

                            if (pTS !== null) {
                                tsValue = parseFloat(data.ts)
                                if (isNaN(tsValue)) {
                                    tsValue = 0
                                }
                                let ts_typeValue = Boolean(data.tstype)
                                if (ts_typeValue === false) {
                                    tsValue = (ht - (remise_value * qte.value.replaceAll(' ', '').replaceAll(',', '.'))) * (tsValue / 100)
                                } else {
                                    tsValue = tsValue * qte.value
                                }
                                pTS.value = number_format(tsValue, 2, ".", " ")
                            }

                            pHTA.value = number_format(ht_rounded, 2, ".", " ")
                            pTTCA.value = number_format((((ht - (remise_value * qte.value.replaceAll(' ', '').replaceAll(',', '.')))) + tsValue) + (((ht - (remise_value * qte.value.replaceAll(' ', '').replaceAll(',', '.'))) + tsValue) * (taux / 100)), 2, ".", " ")

                            calculTotal()
                        }
                    }

                    let _type_facture = document.querySelector("select#type_facture")
                    if (_type_facture !== null) {
                        _type_facture.dispatchEvent(new Event('change'))
                    }
                })
            })
        }
    }

    getCardArticle()

    // DynamicRow
    let dynamicRow = document.querySelector(".dynamicRow")
    if (dynamicRow !== null) {
        let td_bordered = dynamicRow.querySelector('.td_bordered')
        let emptyRow = dynamicRow.querySelector('#emptyRow')
        if (td_bordered !== null) {
            emptyRow.style.display = 'none'
        } else {
            emptyRow.style.display = 'table-row'
        }

        const targetNode = document.querySelector('table');
        const observer = new MutationObserver((mutationsList, observer) => {
            let td_bordered = dynamicRow.querySelector('.td_bordered')
            let emptyRow = dynamicRow.querySelector('#emptyRow')
            if (td_bordered !== null) {
                emptyRow.style.display = 'none'
            } else {
                emptyRow.style.display = 'table-row'
            }

            dynamicRow.querySelectorAll(".td_bordered").forEach((item) => {

                let stock = item.dataset.stk
                let pu = item.querySelector("input[name='pu[]']")
                let quantite = item.querySelector("input[name='quantite[]']")
                let tva = item.querySelector("input[name='tva[]']")
                let data_tva = item.querySelector("input[name='data_tva[]']")
                let pTTC = item.querySelector("input[name='pTTC[]']")
                let pHT = item.querySelector("input[name='pHT[]']")
                let deleteRow = item.querySelector(".deleteRow")
                let remise = item.querySelector("input[name='remise[]']")
                let remiseValue = 0

                if (remise != null) {
                    const regex = /^\d+(\.\d{0,2})?%?$/;
                    remise.addEventListener("keydown", event => {
                        if (!regex.test(event.key) && event.key !== "Backspace" && event.key !== "%" && event.key !== "Tab" && event.key !== "." && event.ctrlKey !== true) {
                            event.preventDefault();
                        }
                    });
                    remise.addEventListener("blur", (e) => {
                        let value = remise.value
                        if (value === '') {
                            value = '0.0';
                            remise.value = 0;
                        }
                        if (value.includes('%')) {
                            value = parseFloat(value.split("%")[0].replaceAll(' ', '').replaceAll(',', '.'))
                            value = parseFloat(value)
                            if (value > 100) {
                                remise.value = '100%';
                                value = 100;
                            }

                            let ht = ((round(parseFloat(pu.value.replaceAll(' ', '').replaceAll(',', '.')) * (1 + ((tva.value / 100))), 10) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + ((tva.value / 100))))
                            let ht_rounded = round((round(parseFloat(pu.value.replaceAll(' ', '').replaceAll(',', '.')) * (1 + ((tva.value / 100))), 10) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + ((tva.value / 100))))

                            remiseValue = (parseFloat(pu.value.replaceAll(' ', '').replaceAll(',', '.')) * (value / 100)) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))
                            ht -= remiseValue

                            if (item.querySelector("input[name='data_tstype[]']").value === "true") {
                                let ts = ht * (parseFloat(item.querySelector("input[name='data_ts[]']").value.replaceAll(' ', '').replaceAll(',', '.')) / 100)
                                item.querySelector("input[name='ts[]']").value = number_format(ts, 2, ".", " ")
                                let ttc = (ht + ts) + ((ht + ts) * (tva.value / 100))
                                pTTC.value = number_format(ttc, 2, ".", " ")
                                calculTotal()
                            } else {
                                let ts = parseFloat(item.querySelector("input[name='data_ts[]']").value.replaceAll(' ', '').replaceAll(',', '.')) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))
                                let ttc = (ht + ts) + ((ht + ts) * (tva.value / 100))
                                pTTC.value = number_format(ttc, 2, ".", " ")
                                calculTotal()
                            }

                        } else {
                            value = parseFloat(value.replaceAll(' ', '').replaceAll(',', '.'))
                            let price = parseFloat(item.querySelector("input[name='pHT[]']").value.replaceAll(' ', '').replaceAll(',', '.'))
                            if (value > price) {
                                remise.value = price;
                                value = price;
                            }
                            remiseValue = value * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))
                            if (item.querySelector("input[name='data_tstype[]']").value === "true") {
                                let ht = ((round(parseFloat(pu.value.replaceAll(' ', '').replaceAll(',', '.')) * (1 + (tva.value / 100))) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + (tva.value / 100))) - remiseValue
                                let ts = ht * (parseFloat(item.querySelector("input[name='data_ts[]']").value.replaceAll(' ', '').replaceAll(',', '.')) / 100)
                                item.querySelector("input[name='ts[]']").value = number_format(ts, 2, ".", " ")
                                let ttc = (ht + ts) + ((ht + ts) * (tva.value / 100))
                                pTTC.value = number_format(ttc, 2, ".", " ")
                                calculTotal()
                            } else {
                                let ht = ((round(parseFloat(pu.value.replaceAll(' ', '').replaceAll(',', '.')) * (1 + (tva.value / 100))) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + (tva.value / 100))) - remiseValue
                                let ts = parseFloat(item.querySelector("input[name='data_ts[]']").value.replaceAll(' ', '').replaceAll(',', '.')) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))
                                let ttc = (ht + ts) + ((ht + ts) * (tva.value / 100))
                                pTTC.value = number_format(ttc, 2, ".", " ")
                                calculTotal()
                            }
                        }
                    })
                }

                deleteRow.addEventListener('click', (e) => {
                    e.preventDefault()
                    item.remove()
                })

                const regex = /^\d+(\.\d{0,2})?%?$/;
                pu.addEventListener("keydown", event => {
                    if (!regex.test(event.key) && event.key !== "Backspace" && event.key !== "Tab" && event.ctrlKey !== true) {
                        event.preventDefault();
                    }
                });

                pu.addEventListener("blur", (e) => {
                    let value = parseFloat(pu.value.replaceAll(' ', '').replaceAll(',', '.'))
                    if (value <= 0 || isNaN(value)) {
                        pu.value = 1
                        value = 1
                    }
                    let ht = ((round(parseFloat(pu.value.replaceAll(' ', '').replaceAll(',', '.')) * (1 + (tva.value / 100))) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + (tva.value / 100)))
                    let ht_rounded = round((round(parseFloat(pu.value.replaceAll(' ', '').replaceAll(',', '.')) * (1 + (tva.value / 100))) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + (tva.value / 100)))
                    let ttc = (ht) + (ht * (tva.value / 100))

                    if (item.querySelector("input[name='data_tstype[]']") !== null) {
                        let remise = 0;
                        let ts = 0;
                        if (item.querySelector("input[name='data_tstype[]']").value === "true") {
                            if (item.querySelector("input[name='remise[]']") !== null) {
                                remise = item.querySelector("input[name='remise[]']").value.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.')
                            }
                            ts = ht * (parseFloat(item.querySelector("input[name='data_ts[]']").value.replaceAll(' ', '').replaceAll(',', '.')) / 100)
                        } else {
                            if (item.querySelector("input[name='remise[]']") !== null) {
                                remise = item.querySelector("input[name='remise[]']").value.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.')
                            }
                            ts = parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.')) * (parseFloat(item.querySelector("input[name='data_ts[]']").value.replaceAll(' ', '').replaceAll(',', '.')))

                        }

                        if (remise.toString().includes('%')) {
                            remise = (parseFloat(pu.value.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.')) * (parseFloat(remise.split("%")[0].replaceAll(' ', '').replaceAll(',', '.')) / 100)) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))
                        } else {
                            remise *= parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))
                        }

                        item.querySelector("input[name='ts[]']").value = number_format(ts, 2, ".", " ")
                        ttc = ((ht - remise) + ts) + (((ht - remise) + ts) * (parseFloat(tva.value.replaceAll(' ', '').replaceAll(',', '.')) / 100))
                    }

                    pHT.value = number_format(ht_rounded, 2, ".", " ")
                    pTTC.value = number_format(ttc, 2, ".", " ")
                    calculTotal()
                })

                quantite.addEventListener("keydown", event => {
                    if (!regex.test(event.key) && event.key !== "Backspace" && event.key !== "Tab" && event.key !== "." && event.ctrlKey !== true) {
                        event.preventDefault();
                    }
                });
                quantite.addEventListener("blur", (e) => {
                    let value = parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))
                    if (value <= 0 || isNaN(value)) {
                        quantite.value = 1
                        value = 1
                    }
                    if (stock !== undefined && stock != null) {
                        let _stk = parseFloat(stock)
                        if (value > _stk) {
                            quantite.value = _stk
                            value = _stk
                        }
                    }


                    let ht_rounded = round((round(parseFloat(pu.value.replaceAll(' ', '').replaceAll(',', '.')) * (1 + (tva.value / 100))) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + (tva.value / 100)))
                    let ht = ((round(parseFloat(pu.value.replaceAll(' ', '').replaceAll(',', '.')) * (1 + (tva.value / 100))) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + (tva.value / 100)))
                    let ttc = round((ht) * (1 + (tva.value / 100)))

                    if (item.querySelector("input[name='data_tstype[]']") !== null) {
                        let remise = 0;
                        let ts = 0;
                        if (item.querySelector("input[name='data_tstype[]']").value === "true") {
                            if (item.querySelector("input[name='remise[]']") !== null) {
                                remise = item.querySelector("input[name='remise[]']").value.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.')
                            }
                            ts = ht * (parseFloat(item.querySelector("input[name='data_ts[]']").value.replaceAll(' ', '').replaceAll(',', '.')) / 100)
                        } else {
                            if (item.querySelector("input[name='remise[]']") !== null) {
                                remise = item.querySelector("input[name='remise[]']").value.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.')
                            }
                            ts = parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.')) * (parseFloat(item.querySelector("input[name='data_ts[]']").value.replaceAll(' ', '').replaceAll(',', '.')))

                        }

                        if (remise.toString().includes('%')) {
                            remise = (parseFloat(pu.value.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.')) * (parseFloat(remise.split("%")[0].replaceAll(' ', '').replaceAll(',', '.')) / 100)) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))
                        } else {
                            remise *= parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))
                        }

                        item.querySelector("input[name='ts[]']").value = number_format(ts, 2, ".", " ")
                        ttc = ((ht - remise) + ts) + (((ht - remise) + ts) * (parseFloat(tva.value.replaceAll(' ', '').replaceAll(',', '.')) / 100))
                    }

                    pHT.value = number_format(ht_rounded, 2, ".", " ")
                    pTTC.value = number_format(ttc, 2, ".", " ")
                    calculTotal()
                })

                const regexTva = /^\d{0,3}(\.\d{0,2})$/;
                tva.addEventListener("keydown", event => {
                    if (!regex.test(event.key) && event.key !== "Backspace" && event.key !== "Tab" && event.key !== "." && event.ctrlKey !== true) {
                        event.preventDefault();
                    }
                });
                tva.addEventListener("blur", (e) => {
                    let value = parseFloat(tva.value.replaceAll(' ', '').replaceAll(',', '.'))
                    if (value < 0 || isNaN(value)) {
                        tva.value = "0.0"
                        value = 0
                    }
                    if (value > 100) {
                        tva.value = 100
                        value = 100
                    }

                    if (data_tva) {
                        data_tva.setAttribute('data-change', value)
                    }

                    let ht = ((round(parseFloat(pu.value.replaceAll(' ', '').replaceAll(',', '.')) * (1 + (tva.value / 100))) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + (tva.value / 100)))
                    let ht_rounded = round((round(parseFloat(pu.value.replaceAll(' ', '').replaceAll(',', '.')) * (1 + (tva.value / 100))) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))) / (1 + (tva.value / 100)))
                    let ttc = (ht) + (ht * (tva.value / 100))

                    if (item.querySelector("input[name='data_tstype[]']") !== null) {
                        let remise = 0;
                        let ts = 0;
                        if (item.querySelector("input[name='data_tstype[]']").value === "true") {
                            if (item.querySelector("input[name='remise[]']") !== null) {
                                remise = item.querySelector("input[name='remise[]']").value.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.')
                            }
                            ts = ht * (parseFloat(item.querySelector("input[name='data_ts[]']").value.replaceAll(' ', '').replaceAll(',', '.')) / 100)
                        } else {
                            if (item.querySelector("input[name='remise[]']") !== null) {
                                remise = item.querySelector("input[name='remise[]']").value.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.')
                            }
                            ts = parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.')) * (parseFloat(item.querySelector("input[name='data_ts[]']").value.replaceAll(' ', '').replaceAll(',', '.')))

                        }

                        if (remise.toString().includes('%')) {
                            remise = (parseFloat(pu.value.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.')) * (parseFloat(remise.split("%")[0].replaceAll(' ', '').replaceAll(',', '.')) / 100)) * parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))
                        } else {
                            remise *= parseFloat(quantite.value.replaceAll(' ', '').replaceAll(',', '.'))
                        }

                        item.querySelector("input[name='ts[]']").value = number_format(ts, 2, ".", " ")
                        ttc = ((ht - remise) + ts) + (((ht - remise) + ts) * (parseFloat(tva.value.replaceAll(' ', '').replaceAll(',', '.')) / 100))
                    }

                    pHT.value = number_format(ht_rounded, 2, ".", " ")
                    pTTC.value = number_format(ttc, 2, ".", " ")
                    pTTC.value = number_format(ttc, 2, ".", " ")
                    calculTotal()
                })
            })

            calculTotal()
        });
        observer.observe(targetNode, {attributes: true, childList: true, subtree: true});

        // dynamicRow.querySelector("table").addEventListener('DOMSubtreeModified', () => {
        // })

    }

    let client_calcul_total = document.querySelector("select.calcul_total")

    client_calcul_total?.addEventListener('change', () => {
        calculTotal()
    })

    let type_facture = document.querySelector('select#type_facture')
    type_facture?.addEventListener('change', get_facture_exoneration)

    let _client_facture = document.querySelector('select#client_id')
    _client_facture?.addEventListener('change', get_facture_exoneration)
    _client_facture?.addEventListener('change', get_client_comptant)

    function get_facture_exoneration() {

        let _type_precompte = document.querySelector('select#type_precompte')
        let commentaires = document.querySelector('textarea#commentaires')

        let type_facture_value = type_facture.value
        let client = document.querySelector("select#client_id").options[document.querySelector("select#client_id").selectedIndex]
        let exoneration = client.dataset.exoneration
        let ref_exoneration = client.dataset.ref_exoneration
        let dynamicRow = document.querySelector(".dynamicRow")

        if (type_facture_value === "Type facture") {
            type_facture_value = "FV"
        }

        if (["I", "J", "K"].includes(type_facture_value) || ["G", "H", "F"].includes(exoneration)) {
            disable()

            if (exoneration === "G") {
                if (!commentaires.value.includes("Client Exonéré")) {
                    commentaires.value += "Client Exonéré Réf: " + ref_exoneration
                }
            } else {
                commentaires.value = ""
            }

        } else {
            activate()
            commentaires.value = ""
        }


        function disable() {
            _type_precompte.querySelector('option[value="0"]').setAttribute("selected", "")
            _type_precompte.disabled = true
            dynamicRow.querySelectorAll(".td_bordered").forEach((item) => {
                item.setAttribute("row_modified", true)
                let tva = item.querySelector("input[name='tva[]']")
                tva.value = "0.0"
                tva.dispatchEvent(new Event('blur'))
            })
            dynamicRow.setAttribute("data-disable-tva", true)
        }

        function activate() {
            // _type_precompte.querySelector('option[value="0"]').removeAttribute("selected")
            // _type_precompte.querySelector('option[value="0"]').setAttribute("selected", "")
            _type_precompte.disabled = false
            dynamicRow.removeAttribute("data-disable-tva")
            dynamicRow.querySelectorAll(".td_bordered[row_modified]").forEach((item) => {
                item.removeAttribute("row_modified")
                let tva = item.querySelector("input[name='tva[]']")
                let data_tva = item.querySelector("input[name='data_tva[]']")
                tva.value = data_tva.getAttribute("change") ?? data_tva.value
                tva.dispatchEvent(new Event('blur'))
            })
        }
    }


    let _ttc__total = 0;
    let _ttc__general = 0;

    function calculTotal() {
        let somHT = 0
        let somTTC = 0
        let somTVA = 0
        let somTS = 0
        let somRemise = 0

        let allRow = document.querySelectorAll(".dynamicRow .td_bordered")
        allRow.forEach((item) => {
            let pu = item.querySelector("input[name='pu[]']").value
            let qte = item.querySelector("input[name='quantite[]']").value
            let ht = item.querySelector("input[name='pHT[]']").value
            let ttc = item.querySelector("input[name='pTTC[]']").value
            let tva = item.querySelector("input[name='tva[]']").value
            let remise = item.querySelector("input[name='remise[]']")
            let ts = item.querySelector("input[name='ts[]']")

            if (remise !== null) {
                if (remise.value.includes('%')) {
                    remise = (parseFloat(pu.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.')) * (parseFloat(remise.value.split("%")[0].replaceAll(' ', '').replaceAll(',', '.')) / 100)) * parseFloat(qte)
                } else {
                    remise = parseFloat(remise.value.replaceAll(' ', '').replaceAll(',', '.')) * parseFloat(qte)
                }
            } else {
                remise = 0
            }

            if (ts !== null) {
                ts = parseFloat(ts.value.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.'))
            } else {
                ts = 0
            }

            somHT += parseFloat(ht.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.'))
            somTVA += (parseFloat(ttc.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.')) / (100 + (parseFloat(tva)))) * (parseFloat(tva))
            somTS += ts
            somTTC += parseFloat(ttc.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.'))
            somRemise += remise
        })
        document.querySelector(".totalHT").innerHTML = number_format(somHT, 0, ".", " ") + " FCFA"
        document.querySelector(".totalTTC").innerHTML = number_format(somTTC, 0, ".", " ") + " FCFA"
        document.querySelector(".totalTVA").innerHTML = number_format(somTVA, 0, ".", " ") + " FCFA"
        if (document.querySelector(".totalRem") !== null) {
            document.querySelector(".totalRem").innerHTML = number_format(somRemise, 0, ".", " ") + " FCFA"
        }
        if (document.querySelector(".totalTS") !== null) {
            document.querySelector(".totalTS").innerHTML = number_format(somTS, 0, ".", " ") + " FCFA"
        }
        if (document.querySelector(".totalTS") !== null) {
            document.querySelector(".totalTS").innerHTML = number_format(somTS, 0, ".", " ") + " FCFA"
        }

        getPIsbTable()

        let _isb = document.querySelector(".totalISB")?.innerHTML
        if (_isb === undefined) {
            _isb = 0
        } else {
            _isb = parseFloat(_isb.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.'))
            if (isNaN(_isb)) _isb = 0
        }
        _ttc__total = somTTC
        _ttc__general = somTTC + _isb

        document.querySelector(".totalTTC").innerHTML = number_format(somTTC + _isb, 0, ".", " ") + " FCFA"

        getControlTotal(_ttc__general)
    }

    function getPIsbTable() {
        let type_precompte = document.querySelector("select#type_precompte")
        if (type_precompte !== null) {
            getIsb()
            type_precompte.addEventListener("change", (e) => {
                getIsb()
            })

        }

        function getIsb() {
            let client_nif = parseFloat(document.querySelector("select#client_id").options[document.querySelector("select#client_id").selectedIndex].getAttribute('data-nif'))
            let value = parseInt(type_precompte.value, 10)

            if (value === 0) {
                let table = document.querySelectorAll(".dynamicRow .td_bordered")
                table.forEach((item) => {
                    if (item.querySelector("input[name='pisb[]']") !== null) {
                        item.querySelector("input[name='pisb[]']").value = ""
                    }
                })
                document.querySelector(".totalISB").innerHTML = "Aucun"
                document.querySelector(".totalTTC").innerHTML = number_format(_ttc__total, 0, ".", " ") + " FCFA"

            } else if (value === 1) {
                let somPisb = 0;
                let table = document.querySelectorAll(".dynamicRow .td_bordered")
                table.forEach((item) => {
                    let ttc = item.querySelector("input[name='pTTC[]']").value
                    ttc = parseFloat(ttc.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.'))

                    if (!isNaN(client_nif)) {
                        somPisb += ttc * 0.02
                        if (item.querySelector("input[name='pisb[]']") !== null) item.querySelector("input[name='pisb[]']").value = number_format(ttc * 0.02, 0, ".", " ") + " (2%)"
                    } else {
                        somPisb += ttc * 0.05
                        if (item.querySelector("input[name='pisb[]']") !== null) item.querySelector("input[name='pisb[]']").value = number_format(ttc * 0.05, 0, ".", " ") + " (5%)"
                    }
                })
                document.querySelector(".totalISB").innerHTML = number_format(somPisb, 0, ".", " ") + " FCFA"
                document.querySelector(".totalTTC").innerHTML = number_format(_ttc__total + somPisb, 0, ".", " ") + " FCFA"

                _ttc__general = _ttc__total + somPisb

                getControlTotal(_ttc__general)
            } else {

                let table = document.querySelectorAll(".dynamicRow .td_bordered")
                let somPisb = 0;
                table.forEach((item) => {
                    let ttc = item.querySelector("input[name='pTTC[]']").value
                    ttc = parseFloat(ttc.split(" FCFA")[0].replaceAll(' ', '').replaceAll(',', '.'))
                    if (!isNaN(client_nif)) {
                        somPisb += ttc * 0.02
                    } else {
                        somPisb += ttc * 0.05
                    }
                    if (item.querySelector("input[name='pisb[]']") !== null) {
                        item.querySelector("input[name='pisb[]']").value = ""
                    }
                })

                document.querySelector(".totalISB").innerHTML = number_format(somPisb, 0, ".", " ") + " FCFA"
                document.querySelector(".totalTTC").innerHTML = number_format(_ttc__total + somPisb, 0, ".", " ") + " FCFA"

                _ttc__general = _ttc__total + somPisb

                getControlTotal(_ttc__general)
            }

        }
    }


    function getControlTotal(amountSum) {
        let _mode_paiement_facture = document.querySelector('select#mode_paiement_id')
        let _timbre_facture = document.querySelector('select#timbre')

        if (_timbre_facture) {
            if (amountSum > 3000000 && _mode_paiement_facture.value == "5" && _mode_paiement_facture.dataset?.verified != 'true') {
                if (_mode_paiement_facture.dataset.confirmbox !== "true") {
                    _mode_paiement_facture.setAttribute('data-confirmbox', true)
                    $.confirm({
                        icon: 'fa fa-question',
                        title: 'Confimation',
                        content: "Attention ! le montant de la facture est supérieur à <br> <b>3.000.000</b>. Confirmez-vous le mode de règlement en <b>espèces</b> ?",
                        useBootstrap: false,
                        boxWidth: '500px',
                        // autoClose: 'Annuler|10000',
                        theme: 'material',
                        // closeIcon: true,
                        animation: 'scale',
                        type: 'blue',
                        buttons: {
                            oui: {
                                btnClass: 'btn__confirm__blue',
                                action: function () {
                                    _mode_paiement_facture.removeAttribute('data-confirmbox')
                                    _mode_paiement_facture.setAttribute('data-verified', true)
                                    if (_timbre_facture) {
                                        _timbre_facture.parentElement.classList.add('danger')
                                    }

                                }
                            },
                            non: function () {
                                _mode_paiement_facture.removeAttribute('data-confirmbox')

                                if (document.querySelector("select#client_id").options[document.querySelector("select#client_id").selectedIndex].dataset.comptant == "true") {
                                    document.querySelector("select#client_id").options[document.querySelector("select#client_id").selectedIndex].removeAttribute('selected')
                                }

                                if (_timbre_facture) {
                                    _timbre_facture.parentElement.classList.remove('danger')
                                    _timbre_facture.querySelector("option[value='0']").disabled = false
                                    _timbre_facture.querySelector("option[value='0']").setAttribute('selected', '')
                                }

                                _mode_paiement_facture.setAttribute('data-verified', true)
                                _mode_paiement_facture.querySelector("option[selected]")?.removeAttribute('selected')
                                _mode_paiement_facture.querySelector("option[value='1']").setAttribute('selected', '')
                            }
                        }
                    });
                }
            }
            if (amountSum > 3000000 && _mode_paiement_facture.value != "5" && _timbre_facture.value == "1" && _mode_paiement_facture.dataset?.verified2 != 'true') {
                $.confirm({
                    icon: 'fa fa-question',
                    title: 'Confimation',
                    content: "Attention ! le montant de la facture est supérieur à <br> <b>3.000.000</b> et le timbre fiscal est activité. Voulez-vous continuer ?",
                    useBootstrap: false,
                    boxWidth: '500px',
                    // autoClose: 'Annuler|10000',
                    theme: 'material',
                    // closeIcon: true,
                    animation: 'scale',
                    type: 'blue',
                    buttons: {
                        oui: {
                            btnClass: 'btn__confirm__blue',
                            action: function () {
                                _mode_paiement_facture.setAttribute('data-verified2', true)
                                if (_timbre_facture) {
                                    _timbre_facture.parentElement.classList.add('danger')
                                }
                            }
                        },
                        non: function () {
                            _mode_paiement_facture.setAttribute('data-verified2', true)
                            if (_timbre_facture) {
                                _timbre_facture.parentElement.classList.remove('danger')
                                _timbre_facture.querySelector("option[value='0']").disabled = false
                                _timbre_facture.querySelector("option[value='0']").setAttribute('selected', '')
                            }
                        }
                    }
                });
            }
        }
    }

    /**
     * Timbre
     * @type {Element}
     * @private
     */
    let _mode_paiement_facture = document.querySelector('select#mode_paiement_id')
    let _timbre_facture = document.querySelector('select#timbre')
    _mode_paiement_facture?.addEventListener('change', get_facture_mode_paiement)
    _timbre_facture?.addEventListener('change', get_facture_timbre)
    if (_mode_paiement_facture) {
        get_facture_mode_paiement()
        if (_timbre_facture) {
            get_facture_timbre()
        }
    }

    function get_facture_mode_paiement() {
        if (document.querySelector(".totalTimbre")) {

            let value = _mode_paiement_facture.value
            let value_timbre = _timbre_facture?.value || 1
            let last_value = _mode_paiement_facture.dataset.last || value

            if (value == "5") {
                if (_timbre_facture) {
                    _timbre_facture.querySelector("option[value='0']").removeAttribute('selected')
                    _timbre_facture.querySelector("option[value='1']").setAttribute('selected', '')
                }

                if (last_value != "5" && _mode_paiement_facture.dataset?.verified == 'true') {
                    _mode_paiement_facture.removeAttribute('data-verified')
                }
            } else {

                if (last_value != value && _mode_paiement_facture.dataset?.verified2 == 'true') {
                    _mode_paiement_facture.removeAttribute('data-verified2')
                }

                if (_timbre_facture) {
                    _timbre_facture.querySelector("option[value='0']").disabled = false
                    _timbre_facture.querySelector("option[value='0']").setAttribute('selected', '')
                }
            }

            _mode_paiement_facture.setAttribute('data-last', value)


            if (_timbre_facture) {
                _timbre_facture.disabled = false
            }

            if (value_timbre == "1") {
                document.querySelector(".totalTimbre").innerHTML = number_format(200, 0, ".", " ") + " FCFA"
            }

            if (_ttc__general > 3000000 && value == "5" && _mode_paiement_facture.dataset?.verified != 'true') {
                $.confirm({
                    icon: 'fa fa-question',
                    title: 'Confimation',
                    content: "Attention ! le montant de la facture est supérieur à <br> <b>3.000.000</b>. Confirmez-vous le mode de règlement en <b>espèces</b> ?",
                    useBootstrap: false,
                    boxWidth: '500px',
                    // autoClose: 'Annuler|10000',
                    theme: 'material',
                    // closeIcon: true,
                    animation: 'scale',
                    type: 'blue',
                    buttons: {
                        oui: {
                            btnClass: 'btn__confirm__blue',
                            action: function () {
                                _mode_paiement_facture.setAttribute('data-verified', true)
                                if (_timbre_facture) {
                                    _timbre_facture.parentElement.classList.add('danger')
                                }
                            }
                        },
                        non: function () {
                            _mode_paiement_facture.setAttribute('data-verified', true)

                            if (document.querySelector("select#client_id").options[document.querySelector("select#client_id").selectedIndex].dataset.comptant == "true") {
                                document.querySelector("select#client_id").options[document.querySelector("select#client_id").selectedIndex].removeAttribute('selected')
                            }

                            if (_timbre_facture) {
                                _timbre_facture.parentElement.classList.remove('danger')
                                _timbre_facture.querySelector("option[value='0']").disabled = false
                                _timbre_facture.querySelector("option[value='0']").setAttribute('selected', '')
                            }

                            _mode_paiement_facture.querySelector("option[selected]")?.removeAttribute('selected')
                            _mode_paiement_facture.querySelector("option[value='1']").setAttribute('selected', '')
                        }
                    }
                });
            }
        }
    }

    function get_facture_timbre() {
        let value_timbre = _timbre_facture?.value || 1
        _mode_paiement_facture.removeAttribute('data-verified2')

        if (value_timbre == 1 && !_timbre_facture.disabled) {
            document.querySelector(".totalTimbre").innerHTML = number_format(200, 0, ".", " ") + " FCFA"

            if (_ttc__general > 3000000 && _mode_paiement_facture.value != "5" && _mode_paiement_facture.dataset?.verified2 != 'true') {
                $.confirm({
                    icon: 'fa fa-question',
                    title: 'Confimation',
                    content: "Attention ! le montant de la facture est supérieur à <br> <b>3.000.000</b> et le timbre fiscal est activité. Voulez-vous continuer ?",
                    useBootstrap: false,
                    boxWidth: '500px',
                    // autoClose: 'Annuler|10000',
                    theme: 'material',
                    // closeIcon: true,
                    animation: 'scale',
                    type: 'blue',
                    buttons: {
                        oui: {
                            btnClass: 'btn__confirm__blue',
                            action: function () {
                                _mode_paiement_facture.setAttribute('data-verified2', true)
                                if (_timbre_facture) {
                                    _timbre_facture.parentElement.classList.add('danger')
                                }
                            }
                        },
                        non: function () {
                            _mode_paiement_facture.setAttribute('data-verified2', true)
                            if (_timbre_facture) {
                                _timbre_facture.parentElement.classList.remove('danger')
                                _timbre_facture.querySelector("option[value='0']").disabled = false
                                _timbre_facture.querySelector("option[value='0']").setAttribute('selected', '')
                            }
                        }
                    }
                });
            }

        } else {
            if (_timbre_facture.parentElement.classList.contains('danger')) {
                _timbre_facture.parentElement.classList.remove('danger')
            }
            document.querySelector(".totalTimbre").innerHTML = number_format(0, 0, ".", " ") + " FCFA"
        }
    }


    /**
     * Client comptat
     */
    function get_client_comptant() {

        if (document.querySelector("select#client_id")) {

            let _comptant_client = document.querySelector("select#client_id").options[document.querySelector("select#client_id").selectedIndex]

            if (_comptant_client.dataset.comptant == "true") {
                _timbre_facture?.querySelector("option[value='0']").setAttribute('selected', '')
                _mode_paiement_facture.querySelector("option[value='5']").setAttribute('selected', '')

            } else {
                _mode_paiement_facture.querySelectorAll("option").forEach((item) => {
                    item.disabled = false
                })
            }
        }
    }

    get_client_comptant()

    let get_dynamic_search_article = document.querySelector("#get_dynamic_search_article")
    if (get_dynamic_search_article !== null) {

        let _target = document.querySelector("#dynamic_search_article")
        let hasVerticalScrollbar = _target.scrollHeight > _target.clientHeight
        if (hasVerticalScrollbar) {
            _target.style.paddingRight = "18px";
        } else {
            _target.style.paddingRight = "0";
        }

        let url = get_dynamic_search_article.action
        get_dynamic_search_article.querySelector("input[name='q']").addEventListener('keyup', (e) => {
            getData()
        })
        get_dynamic_search_article.addEventListener('submit', (e) => {
            e.preventDefault()
            getData()
        })

        function getData() {
            let data = new FormData(get_dynamic_search_article)
            if (url !== null && url !== undefined) {
                axios({
                    method: 'post', url: url, responseType: 'text', data: data
                })
                    .then(function (response) {
                        let target = document.querySelector("#dynamic_search_article")

                        if (data === undefined || target === null) {
                            return 0
                        }
                        target.innerHTML = response.data
                        getCardArticle()
                        let _target = document.querySelector("#dynamic_search_article")
                        let hasVerticalScrollbar = _target.scrollHeight > _target.clientHeight
                        if (hasVerticalScrollbar) {
                            _target.style.paddingRight = "18px";
                        } else {
                            _target.style.paddingRight = "0";
                        }
                    }).catch(function (error) {
                    NotificationToast("error", error.message)
                })
            }
        }
    }

    let JS_Form_Submit = document.querySelectorAll(".JS_Form_Submit")
    JS_Form_Submit.forEach((item) => {
        item.addEventListener("click", () => {
            let form = document.querySelector("form#" + item.dataset.formid)
            let control = formControl(form)
            let payload = new FormData(form)
            let url = form.action
            let emptyRow = document.querySelector("#emptyRow")
            emptyRow = emptyRow != null && emptyRow.style.display === "table-row";
            if (control === true) {
                if (emptyRow) {
                    NotificationToast('error', 'Aucune ligne insérée... Veuillez ajouter un article')
                } else if (parseFloat(document.querySelector('.totalTTC').innerText.replaceAll(' FCFA', '')) <= 0) {
                    NotificationToast('error', 'La montant TTC de la facture <b>doit être > 0</b>')
                } else {
                    document.querySelector('.spinner__loader')?.classList.add('active')
                    axios({
                        method: 'post', url: url, responseType: 'json', data: payload
                    })
                        .then(function (response) {
                            let data = response.data
                            let redirect_url = data?.redirection
                            let search_params = new URL(redirect_url).search;
                            let current_search_params = window.location.search;
                            if (search_params !== current_search_params && search_params.search("tab") === -1) {
                                redirect_url += current_search_params;
                            }

                            Turbolinks.visit(redirect_url)
                            document.addEventListener("turbolinks:load", function () {
                                if (data !== undefined) {
                                    if (data.redirection_tab !== undefined) {
                                        window.open(data.redirection_tab, '_blank')
                                    }
                                    NotificationToast(data?.type, data?.message)
                                    setTimeout(() => { //remove data after 500ms
                                        data = undefined;
                                    }, 100);
                                }
                            })
                        }).catch(function (error) {
                        document.querySelector('.spinner__loader')?.classList.remove('active')
                        NotificationToast("error", error.message)
                    });
                }
            }
        })
    })

    // Load all input file preview Img
    loadInputFile()

    // All Form submit in Ajax Promise
    let allFormElement = document.querySelectorAll("form.custom__form")
    allFormElement.forEach((item) => {
        if (item.classList.contains('disabled-all')) {
            let elm = item.elements
            for (let i = 0; i < elm.length; i++) {
                elm[i].disabled = true
                elm[i].style.cursor = 'not-allowed'
            }
        }
        item.addEventListener("submit", (e) => {
            e.preventDefault()
            let formElement = item
            let payload = new FormData(formElement)
            let url = formElement.action
            let formControlResult = formControl(formElement, payload)
            if (formControlResult === true) {
                document.querySelector('.spinner__loader')?.classList.add('active')
                axios({
                    method: 'post', url: url, responseType: 'json', data: payload
                })
                    .then(function (response) {
                        let data = response.data

                        let redirect_url = data?.redirection
                        let search_params = new URL(redirect_url).search;
                        let current_search_params = window.location.search;
                        if (search_params !== current_search_params && search_params.search("tab") === -1) {
                            redirect_url += current_search_params;
                        }

                        Turbolinks.visit(redirect_url)
                        document.addEventListener("turbolinks:load", function () {
                            if (data !== undefined) {
                                if (data.redirection_tab !== undefined) {
                                    window.open(data.redirection_tab, '_blank')
                                }
                                NotificationToast(data?.type, data?.message)
                                setTimeout(() => { //remove data after 500ms
                                    data = undefined;
                                }, 500);
                            }
                        })
                    }).catch(function (error) {
                    document.querySelector('.spinner__loader')?.classList.remove('active')
                    if (error.response.status === 419) {
                        let url = window.location
                        Turbolinks.visit(url.href + url.search)
                        document.addEventListener("turbo:load", function () {
                            NotificationToast("error", "Erreur 419 : Page expirée <br>" + "La session a expiré. Cette page a été automatiquement rafraîchie.")
                        })
                    } else {
                        Turbolinks.visit(window.location.href)
                        document.addEventListener("turbo:load", function () {
                            NotificationToast("error", error.message)
                        })
                    }
                });
            }
        })
    })

    let custom__link = document.querySelectorAll('.custom__link')
    custom__link.forEach((item) => {
        item.addEventListener('click', (e) => {
            e.preventDefault()
            let url = item.dataset.url
            Turbolinks.visit(url)
        })
    })

    // Show/Hidden Password
    let password_field = document.querySelectorAll("#passswordToggle")
    password_field.forEach((item) => {
        item.addEventListener("click", () => {
            let statut = item.dataset.statut
            if (statut === "hidden") {
                item.parentElement.querySelector("input").type = "text"
                item.dataset.statut = "visible"
                item.classList.remove('fi-rr-eye-crossed')
                item.classList.add('fi-rr-eye')
            } else {
                item.parentElement.querySelector("input").type = "password"
                item.dataset.statut = "hidden"
                item.classList.add('fi-rr-eye-crossed')
                item.classList.remove('fi-rr-eye')
            }
        })
    })

    let JSlogout__user = document.querySelector("#JSlogout__user")
    JSlogout__user?.addEventListener("click", (e) => {
        e.preventDefault()
        CustomConfirmLogout(JSlogout__user.dataset.url)
    })
    /*
     */
    let validateForm = document.querySelectorAll("form.validateForm")
    validateForm.forEach((item) => {
        item.addEventListener("submit", (e) => {
            e.preventDefault();
            let text = item.dataset.content
            CustomConfirmValidation(text, item)
        })
    })


    /**
     *
     * @type {Element}
     */
    const chart__stats_year = document.querySelector("#chart__stats_year")
    if (chart__stats_year !== null) {

        const data = data_revenu;
        let datasets = [];
        Object.keys(data).forEach(function (key, index) {
            datasets.push({
                label: key,
                data: Object.values(data[key]),
                pointStyle: 'circle',
                pointRadius: 5,
                pointHoverRadius: 8,
                tension: 0.2
            })
        });

        new Chart(chart__stats_year, {
            type: 'line', data: {
                labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
                datasets: datasets
            }, options: {
                plugins: {
                    autocolors, legend: {
                        labels: {
                            // This more specific font property overrides the global property
                            font: {
                                family: 'sans-serif', weight: '500'
                            }
                        }
                    }, tooltip: {
                        bodyFont: {
                            family: 'sans-serif', weight: '400'
                        }, titleFont: {
                            family: 'sans-serif', weight: '400'
                        }
                    }, subtitle: {
                        display: true,
                        text: 'Situation Charges/Revenus ' + (!isNaN(parseInt(getRequest('year'))) ? parseInt(getRequest('year')) : new Date().getFullYear()),
                        font: {
                            family: 'sans-serif', weight: '700'
                        }
                    }
                }, scales: {
                    y: {
                        ticks: {
                            font: {
                                family: 'sans-serif', weight: '500'
                            }
                        }
                    }, x: {
                        ticks: {
                            font: {
                                family: 'sans-serif', weight: '500'
                            }
                        }
                    }
                }
            }
        });
    }


    let mode_paiement_id = document.querySelector('#mode_paiement_id')
    let banque_id = document.querySelector('#banque_id')
    if (banque_id !== null) {
        let ids = [1, 2];
        mode_paiement_id.addEventListener('change', (e) => {
            if (ids.includes(parseInt(mode_paiement_id.value, 10))) {
                banque_id.setAttribute('data-control', 'noEmpty')
            } else {
                banque_id.removeAttribute('data-control')
                banque_id.parentElement.parentElement.classList.remove('error')
            }
        })
    }
})

// Functions
function CloseModal() {
    const popUpModal = document.querySelector(".modal__box")
    popUpModal.classList.toggle("active")
    setTimeout(function () {
        document.querySelector("#JS_GenerateForm").innerHTML = ""
    }, 200)
}

function submitForm(element) {
    element.setAttribute("disabled", "")
    let animation = element.parentElement.parentElement
    let formParent = element.parentElement.parentElement.querySelector(".modal__body")
    let formElement = formParent.querySelector(".modal__body form")
    let payload = new FormData(formElement)
    let url = formElement.action
    let formControlResult = formControl(formElement, payload)

    if (formControlResult === true) {
        animation.classList.add("show__loader")

        if (formElement.dataset.submit === "false") {
            formElement.submit()
            return 0;
        }

        axios({
            method: 'post', url: url, responseType: 'json', data: payload
        })
            .then(function (response) {
                let data = response.data

                let redirect_url = data?.redirection
                let search_params = new URL(redirect_url).search;
                let current_search_params = window.location.search;
                if (search_params !== current_search_params && search_params.search("tab") === -1) {
                    redirect_url += current_search_params;
                }
                animation.classList.add("show__loader")

                if (data?.target !== null && data?.target === "_blank") {
                    CloseModal()
                    window.open(redirect_url, '_blank');
                    return 0
                }

                Turbolinks.visit(redirect_url)
                document.addEventListener("turbolinks:load", function () {
                    if (data !== undefined) {
                        if (data.redirection_tab !== undefined) {
                            window.open(data.redirection_tab, '_blank')
                        }
                        NotificationToast(data?.type, data?.message)
                        setTimeout(() => { //remove data after 500ms
                            data = undefined;
                        }, 500);
                    }
                })
            }).catch(function (error) {
            element.removeAttribute("disabled")
            animation.classList.remove("show__loader")
            if (error.response.status === 419) {
                let url = window.location
                Turbolinks.visit(url.href + url.search)
                document.addEventListener("turbo:load", function () {
                    NotificationToast("error", "Erreur 419 : Page expirée <br>" + "La session a expiré. Cette page a été automatiquement rafraîchie.")
                })
            } else {
                Turbolinks.visit(window.location.href)
                document.addEventListener("turbo:load", function () {
                    NotificationToast("error", error.message)
                })
            }
        });
    }
    setTimeout(() => {
        element.removeAttribute("disabled")
    }, 1000)
}

/**
 * Custom input file preview
 */
function loadInputFile() {

    let allInputUploadImage = document.querySelectorAll(".JS_load__previewImg");
    allInputUploadImage.forEach((item) => {
        let uploadImagePreview = document.querySelector("#load__previewImg_" + item.name)
        let uploadImageDeletePreview = item.parentElement.querySelector(".deleteUploadPreview")
        item.addEventListener("change", () => {
            if (item.files && item.files.length === 1 && item.files[0].size > 2097152) {
                customAlert("La taille de l'image <b>" + item.files[0].name + "</b> ne doit pas dépasser <b>2MB</b>.")
                item.value = null;
                uploadImagePreview.setAttribute('src', uploadImagePreview.dataset.img);
                uploadImageDeletePreview.style.display = "none";
            } else {
                const fileSrc = item.files[0];
                if (fileSrc) {
                    const reader = new FileReader();
                    reader.addEventListener("load", () => {
                        if (item.files[0].type.indexOf("image") !== -1) uploadImagePreview.setAttribute('src', reader.result);
                        uploadImageDeletePreview.style.display = "block";
                    });
                    reader.readAsDataURL(fileSrc);
                } else {
                    // item.value = uploadImagePreview.src;
                    if (item.files[0].type.indexOf("image") !== -1) uploadImagePreview.setAttribute('src', uploadImagePreview.dataset.img);
                    uploadImageDeletePreview.style.display = "none";
                }
            }
        })

        uploadImageDeletePreview.addEventListener("click", (e) => {
            e.preventDefault();
            if (item.value != null) {
                item.value = null;
                uploadImagePreview.setAttribute('src', uploadImagePreview.dataset.img);
                uploadImageDeletePreview.style.display = "none";

                let removeInput = document.querySelector("#remove_" + item.name)
                if (removeInput !== null) {
                    removeInput.value = true
                }
            }
        })
    })
}


function toggleEditForm() {
    let switch_btn = document.querySelector(".modal__box .modal__header #JS_editModal")
    if (switch_btn !== null) {
        verifyToggle(switch_btn)
        switch_btn.addEventListener('click', () => {
            verifyToggle(switch_btn)
        })
    }

    function verifyToggle(switch_btn) {
        if (switch_btn.checked === false) {
            let allInput = document.querySelectorAll(".modal__body input")
            allInput.forEach((item) => {
                item.setAttribute('disabled', '')
            })

            let allSelect = document.querySelectorAll(".modal__body select")
            allSelect.forEach((item) => {
                item.setAttribute('disabled', '')
            })

            let allTextArea = document.querySelectorAll(".modal__body textarea")
            allTextArea.forEach((item) => {
                item.setAttribute('disabled', '')
            })

            let submitBtn = document.querySelectorAll(".modal__footer input")
            submitBtn.forEach((item) => {
                item.style.opacity = 0
                item.style.visibility = "hidden"
            })

            let requiredIcon = document.querySelectorAll(".modal__body [data-control*=noEmpty] + label + i")
            requiredIcon.forEach((item) => {
                item.style.opacity = 0
                item.style.visibility = "hidden"
                item.setAttribute('disabled', '')
            })

            let requiredIcon2 = document.querySelectorAll(".modal__body [data-control*=noEmpty] + .ss-main + label +i")
            requiredIcon2.forEach((item) => {
                item.style.opacity = 0
                item.style.visibility = "hidden"
                item.setAttribute('disabled', '')
            })

            let br = document.querySelector('.modal__body + br')
            if (br !== null) {
                br.style.display = "none"
            }
            let required__placeholder = document.querySelector(".required__placeholder")
            if (required__placeholder !== null) {
                required__placeholder.style.display = "none"
            }
        } else {
            let allInput = document.querySelectorAll(".modal__body input")
            allInput.forEach((item) => {
                if (item.getAttribute("forceDisabled") == null) {
                    item.removeAttribute('disabled')
                }
            })

            let allSelect = document.querySelectorAll(".modal__body select")
            allSelect.forEach((item) => {
                if (item.getAttribute("forceDisabled") == null) {
                    item.removeAttribute('disabled')
                }
            })

            let allTextArea = document.querySelectorAll(".modal__body textarea")
            allTextArea.forEach((item) => {
                item.removeAttribute('disabled')
            })

            let submitBtn = document.querySelectorAll(".modal__footer input")
            submitBtn.forEach((item) => {
                item.style.opacity = 1
                item.style.visibility = "visible"
            })

            let requiredIcon = document.querySelectorAll(".modal__body [data-control*=noEmpty] + label + i")
            requiredIcon.forEach((item) => {
                item.style.opacity = 1
                item.style.visibility = "visible"
                item.removeAttribute('disabled')
            })

            let requiredIcon2 = document.querySelectorAll(".modal__body [data-control*=noEmpty] + .ss-main + label + i")
            requiredIcon2.forEach((item) => {
                item.style.opacity = 1
                item.style.visibility = "visible"
                item.removeAttribute('disabled')
            })
            let br = document.querySelector('.modal__body + br')
            if (br !== null) {
                br.style.display = "block"
            }

            let required__placeholder = document.querySelector(".required__placeholder")
            if (required__placeholder !== null) {
                required__placeholder.style.display = "flex"
            }
        }
    }
}

function number_format(number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = Math.round((number + '').replace(/[^0-9+\-Ee.]/g, ''));
    let n = !isFinite(+number) ? 0 : +number, prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point, s = '', toFixedFix = function (n, prec) {
            const k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}


function getRequest(key) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
}
