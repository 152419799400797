import AutoNumeric from "autonumeric";
import NotificationToast from "../libs/NotificationToast";

let autoNumericOptionsFcfa = {
    digitGroupSeparator: ' ',
    decimalCharacter: '.',
    decimalCharacterAlternative: '.',
    decimalPlaces: 0,
    currencySymbolPlacement: AutoNumeric.options.currencySymbolPlacement.suffix,
    roundingMethod: AutoNumeric.options.roundingMethod.halfUpSymmetric,
    negativePositiveSignPlacement: 'p',
    minimumValue: 0,
    maximumValue: 10000000000000
}

export class InputNumeric extends HTMLInputElement {

    connectedCallback() {
        this.type = "text"
        try {

            let max = this.dataset.max
            let min = this.dataset.min
            let decimal = this.dataset.decimal
            let config = autoNumericOptionsFcfa

            this.input = new AutoNumeric(this, config);
            if (max !== undefined) {
                this.input.options.maximumValue(max)
            }
            if (min !== undefined) {
                this.input.options.minimumValue(min)
            }
            if (decimal !== undefined && parseFloat(decimal) > 0) {
                this.input.options.decimalPlaces(decimal)
            }
        } catch (e) {
            if (!(e instanceof Error)) {
                e = new Error(e);
            }
            NotificationToast("error", e.message)

        }
    }

    disconnectedCallback() {
        this.input.remove()
        this.input = null
    }
}


export function InputNumericFromDom(element, value) {
    return new AutoNumeric(element, value, autoNumericOptionsFcfa)
}

